// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/news";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

.news-list {
	h1 {
		@extend %_h2;
	}

	h2 {
		@extend %_h3;

		a {
			color: inherit;
		}
	}

	.more {
		a {
			@extend %button;
			@extend .btn--primary;

			text-decoration: none;
		}
	}
}

.news-list--highlight {
	padding-left: 6rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;

		width: 4.5rem;
	    height: 100%;
	    display: block;

		background-image: url(/files/theme/dist/img/icons/bullhorn.svg);
	    background-size: contain;
	    background-repeat: no-repeat;
	    background-position: center 15%;

	    @include media-query(screen-md) {
			background-position: center center;
		}
	}

	.layout_latest {
		margin-bottom: $base-spacing-unit;

		&:after {
			display: none;
		}
	}

	.content_container {
		@include make-row();

		@include media-query(screen-md) {
			display: flex;
			align-items: center;
		}
	}

	h2 {
		color: $color-text--inverted;
		font-size: 1.2rem;
		font-weight: 500;
	}

	.ce_text {
		@include padding-default();

		@include media-query(screen-md) {
			margin-bottom: 0;
		}

		p {
			color: inherit;
		}
	}

	.more {
		@include padding-default();

		@include media-query(screen-md) {
			text-align: center;
		}

		a {
			background: $color-blue-dark-light;
			color: $color-text--inverted;
			border-width: 2px;
			text-decoration: none;

			&:hover {
				background: rgba($color-page-background, 0.2);
			}
		}
	}
}

.news-full {
	.info {
		font-weight: 700;
	}
}
