// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$headings__font-family: $base-font-family-sans-serif;
$headings__font-weight: 400;

$headlines: (
  h1: (
    sizes: (
      xs: 1.70rem,
      sm: 1.70rem,
      md: 2.25rem,
      lg: 2.25rem,
      xl: 2.25rem
    ),
    line-height: 1.55,
    margin-top: 2rem,
    margin-bottom: 1rem
  ),
  h2: (
    sizes: (
      xs: 1.80rem,
      sm: 1.80rem,
      md: 1.80rem,
      lg: 1.80rem,
      xl: 1.80rem
    ),
    line-height: 1.5,
    margin-top: 2rem,
    margin-bottom: 1rem
  ),
  h3: (
    sizes: (
      xs: 1.275rem,
      sm: 1.275rem,
      md: 1.333333rem,
      lg: 1.333333rem,
      xl: 1.333333rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 0.5rem
  ),
  h4: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: 1,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h5: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: 1,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h6: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: 1,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
);
// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/type";

// --------------------------------------------------

#{headings()} {
	color: $color-blue-dark;
}

// heading sizes 1-6
// [1] make sure, that only the first headline inside an element or module
// is styled this way
@for $i from 1 to 6 {
	.heading--#{$i} {
		&.ce_headline {
	  	@extend %h#{$i};
	  }

	  > #{headings()} { // [1]
			&:first-child {
				@extend %h#{$i};
			}
		}

		.ce_text & {
			@extend %_h#{$i};
		}
	}
}

h1,
h2 {
	text-transform: initial;
  font-weight: 500;
}

// heading alignment
.heading--left {
  text-align: left;
}

.heading--center {
	text-align: center;
}

.text--center {
	text-align: center;
}

h1 + h2,
h1 + .ce_text h2,
h2 + h3,
h2 + .ce_text h3 {
	margin-bottom: $base-spacing-unit !important;
}

h2 + h3 {
  margin-top: 0;
}

p,
ul,
ol,
li {
  //font-size: 14px;
  font-weight: 400;
	// font-weight: $base-font-weight;
  //line-height: 20px;
}
