// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

// VARIABLES
  $color-navigation-main--active:   	$color-brand;
  $color-navigation-main--active-bg:	#D5E8FA;
  $nav--main__item-padding:         	$base-spacing-unit;
  $nav--main__item-padding--sm:     	$base-spacing-unit--xs;
  $nav--main-font:                  	$base-font-family-sans-serif;
  $nav--main-font-weight:           	500;

  $submenu-background:					$color-page-background;

  $nav-mobile-color-background:       	$base-overlay-background;
  $nav-mobile-number-of-menu-points:  	7;

  $menu-icon-width:						3rem;
  $menu-icon-stroke:					7px;


// USED FROM _variables.scss
// $color-text--inverted
// $base-spacing-unit, --xs, --sm
// $base-border
// $base-font-family-sans-serif

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// --------------------------------------------------

@import "../../../nutshell/scss/components/navs";

// --------------------------------------------------

// Main Navigation

// [1] IE9 needs this to make the whole anchor clickable and lets the dropdown-navigation work
// [2] 9999px make sure, the max-height is bigger than the actual submenu height, percentage will reference the header height
.nav--main {
  // font-size: 0.9rem;
  line-height: 1em;
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;

  display:flex;
  justify-content: flex-end;

  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: 0;
    
    text-decoration: none;
    font-weight: $nav--main-font-weight;

    @if ($ie9-support == true) {
	    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // [1]
    }
  }

  a {
    color: inherit;
  }

  span.active,
  span.trail,
  strong.active,
  strong.trail {
    color: $color-navigation-main--active;
  }

	.level_1 {
		> li {
  		margin: 0 0.8rem;
  		
  		> a,
  		> strong {
    		text-transform: uppercase;
    		padding-top: 0.7rem;
    		padding-bottom: 0.5rem;
    		border-bottom: 2px solid transparent;

    		
    		@media screen and (min-width: 1141px) {
        	font-size: 1rem;
        }
  		}
  		
		 	&:hover {

				> a,
				> strong {
					//color: $color-blue-dark;
					border-color: currentColor;
				}
			}
		}
	}


	li.nav-portal {
		@include media-query(screen-offcanvas) {
			display: none;
		}
	}

  .level_2 {
	  z-index: 1001;
	  overflow: hidden;

	  @include media-query(screen-offcanvas) {
		  max-height: 0;
		  opacity: 0;
		  position: absolute;
		  padding: 0 $nav--main__item-padding;
		  margin-left: -$nav--main__item-padding;
		  transition: opacity 0.2s 0.3s, max-height 0s 0.6s;
	  }

	  > li {
		  float: none;
		  padding: 0 $base-spacing-unit--sm;
		  background: $submenu-background;
	  }

	  @include media-query(screen-offcanvas) {
		  > li.first {
			  padding-top: $nav--main__item-padding--sm;
		  }

		  > li.last {
			  padding-bottom: $nav--main__item-padding--sm;
		  }

		  a {
			  color: inherit;
			  background: url(/files/theme/dist/img/icons/navigation-arrow-right.svg) center left / $base-spacing-unit--xs no-repeat;

			  &:hover {
  			  color: $color-brand-secondary;
				  background: url(/files/theme/dist/img/icons/navigation-arrow-right--highlight.svg) center left / $base-spacing-unit--xs no-repeat;
			  }
		  }
		  
		  strong {
  		  color: $color-brand-secondary;
			  background: url(/files/theme/dist/img/icons/navigation-arrow-right--highlight.svg) center left / $base-spacing-unit--xs no-repeat;
      }
		}

	  a,
	  span.active,
	  span.trail,
	  strong.active,
	  strong.trail {
	    padding: ($base-spacing-unit--xs * 1.25) ($base-spacing-unit--xs * 1.75);
	    margin: 0;
	    	  font-size: 0.9rem;
	  }
  }

  li.submenu:hover .level_2 {
	  z-index: 1004;
	  max-height: 9999px;
	  opacity: 1;
	  transition-delay: 0s;
	}

	.level_3 {
		@include media-query(screen-offcanvas) {
			margin-left: $base-spacing-unit--sm;
		}

		li {
			float: none;
		}

		a,
		span.active,
		span.trail,
	  strong.active,
	  strong.trail {
			text-transform: none;
		}
	}
}

// [1] transition for mobile navigation fade in background
// [2] transition for mobile navigation item moving
// [3] remove scrollbar for IE9 as it doesn't support -ms-overflow-style
.nav--mobile {

  @include media-query(screen-offcanvas-max) {
    max-height: 0;
    visibility: hidden;
    transition: max-height 0s 3s, background 0.3s; // [1]
    background: transparent; // [1]
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    visibility: visible;
    font-size: 1.5rem;

    > ul {
      position: relative;
      top: 40%;
      width: 100%;
      transform: translateY(-40%);
      max-height: 100%;
      padding-top: $header-height;
      padding-left: $base-spacing-unit;
      padding-right: $base-spacing-unit;

      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-touch-action: touch;
      -ms-overflow-style: none;
    }

    > ul > li {
    transition: transform 0.3s, opacity 0.3s; // [2]
    opacity: 0; // [2]
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;

      @include media-query(screen-offcanvas) {
        opacity: 1;
      }
    }

    .level_2 {
      li {
        background: transparent;
        padding-left: $base-spacing-unit;
      }
    }

    a,
    strong.active,
    strong.trail {
	    padding-left: $base-spacing-unit;
	    padding-right: $base-spacing-unit;
    }

    a,
    strong.active {
  		background: url(/files/theme/dist/img/icons/navigation-arrow-right.svg) center left / ($base-spacing-unit * 0.45) no-repeat;
	  }

  	strong.active,
  	strong.trail {
  		background: url(/files/theme/dist/img/icons/navigation-arrow-right--highlight-green.svg) center left / ($base-spacing-unit * 0.45) no-repeat;
  		color: $color-brand;
  	}
  	
  	.level_2 {
    	a,
      strong.active,
      strong.trail {
        background-size: ($base-spacing-unit--xs);
  	    padding-left: $base-spacing-unit--sm;
  	    padding-right: $base-spacing-unit;
      }
  	}
  }

  .ie9 & {
	  > ul {
		  overflow-y: auto;
	  }
  }
}

.nav--mobile--active {
  @include media-query(screen-offcanvas-max) {
    max-height: 100%;
    transition: max-height 0s, background 0.3s; // [1]
    background: $nav-mobile-color-background;
    z-index: 1004;

    >ul > li {
      transform: translateY(-#{$base-spacing-unit--sm});
      opacity: 1;

      @for $sec from 1 through $nav-mobile-number-of-menu-points {
        &:nth-child(#{$sec}) {
          transition-delay: #{$sec * 1 / 8}s;
        }
      }
    }
  }
}

// button to display mobile navigation
.nav-toggler {
  font-family: sans-serif;
  position: absolute;
  top: 0;
  right: $base-spacing-unit;
  z-index: 1005;
  display: flex;
  height: 100%;

  @include media-query(screen-offcanvas) {
    display: none;
  }
}

.nav-toggler__button {
  background: none;
  border: none;
  outline: none;
  font-weight: 700;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  color: currentColor;
  padding: ($base-spacing-unit--xs / 2) 0;
}

.menu-icon {
	position: relative;
	width: $menu-icon-width;
	height: $base-line-height * 1rem;
	display: inline-block;
	vertical-align: top;
}

.menu-icon__inner {
	width: 100%;
	background-color: currentcolor;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: $menu-icon-stroke/-2;
  height: $menu-icon-stroke;
  transition: transform 0.15s ease;

	&:after,
	&:before {
	  background-color: currentcolor;
	  position: absolute;
	  display: block;
		content: "";
    width: 100%;
		height: $menu-icon-stroke;
    transition: transform 0.15s ease, opacity 0.15s;
	}

	&:before {
		top: -14px;
	}

	&:after {
	  bottom: -14px;
	}

	.html--fixed & {
		transform: rotate(45deg);

		&:before {
			opacity: 0;
		}

		&:after {
			transform: rotate(-90deg);
			bottom: 0;
		}
	}
}

.nav--footer {

	@include make-col(6);

	@include media-query(screen-lg) {
		@include make-col(3);
	}

	padding-top: $base-spacing-unit;
	padding-bottom: $base-spacing-unit;

	h6 {
		color: $color-text--inverted;
		font-weight: 700;
	}

	a,
	strong {
		color: $color-gray-light;
		font-weight: 300;
		font-size: 0.8rem;
	}

	a:hover {
		color: $color-text--inverted;
		text-decoration: underline;
	}
}

.nav--top {
	display: none;

	@include media-query(screen-offcanvas) {
		display: block
	}

	padding: 0 $base-spacing-unit--sm;
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;

	ul {
		display: flex;
		justify-content: flex-end;
	}

	li {

	}

	a,
	strong {
		color: $color-orange;
		display: block;
		font-family: $base-font-family;
		font-weight: $nav--main-font-weight;
		padding: 0.7rem 0.8rem;
		text-decoration: none;
		text-transform: uppercase;

		&.icon {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -18px;
				top: 0;
				color: $color-orange;
				width: 22px;
				height: 100%;

				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}

		&.icon--login {
			&:before {
				background-image: url(/files/theme/dist/img/icons/sign-in.svg);
			}
		}
	}
}

$nav-meta__mq-xs: 390px;
.nav--meta {
	padding: $base-spacing-unit--sm + 1 0;
	margin-right: -$grid__gutter--half;
	margin-left: -$grid__gutter--half;

	ul {

		@media screen and (min-width: $nav-meta__mq-xs) {
			display: flex;
			justify-content: flex-end;
		}
	}

	li {
		font-size: 1rem;

		@media screen and (min-width: $nav-meta__mq-xs) {
			font-size: 0.8rem;

			&:not(.first):before {
				content: '|';
				padding-left: $base-spacing-unit--xs;
				padding-right: $base-spacing-unit--xs;
			}
		}

		@media screen and (min-width: 440px) {
			font-size: 1rem;
		}
	}

	a,
	strong {
		color: inherit;
		text-decoration: underline;
	}
}
