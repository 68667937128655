/**
 * @component 			Quote
 * @project 			Gemeindewerke St. Michel
 * @last-modified		2019-05-15
 * @authors				Yannick Herzog <info@hit-services.net>
 */

.quote {
	.text_container {
		margin-top: 1rem;
		margin-left: 1.8rem;
		position: relative;

		&:before {
			content: '"';
			font-family: $base-font-family;
			font-size: 4rem;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			transform: translate(-1.8rem,-1.8rem);
		}
	}
}
