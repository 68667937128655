/**
  * Erdmann & Freunde - Nutshell
  * Starterkit
  * erdmann-freunde.de/nutshell/
  *
  * Lizenziert unter MIT OPEN SOURCE
  *
  */


//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background:                   	#ffffff;
$header-color-background--inverted:           $color-gray; //$color-band-dark-background !default;
$header-color-background--inverted-gradient:  $color-gray; //$color-band-dark-background-gradient !default;
$header-color-text:														$color-blue-gray;
$header-color-text--invert:                 	$color-text--inverted;
$header-height:                     					5rem;

$footer-color-background:											#ffffff;
$footer-color-text:														$color-blue-dark;

// USED FROM _layout.scss (nutshell)
// * $header-color-text [$color-text]

// USED FROM _variables.scss
// $base-spacing-unit, --sm, --lg
// $color-band-highlight-background
// $color-band-tint-background
// $color-band-dark-background-gradient
// $color-band-dark-background
// $color-band-dark-text

// --------------------------------------------------

@import "../../../nutshell/scss/base/layout";

// --------------------------------------------------


// [1] sticky footer via flexbox, see https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
body {
	height: 100vh; // [1]
}

// #wrapper
// [1] sticky footer via flexbox
[id="wrapper"] {
  display: flex; // [1]
  flex-direction: column; // [1]
  min-height: 100%; // [1]
}

// #header
[id="header"] {
  background: $header-color-background;
  top: 0;
  position: relative;
  z-index: 1005;
  left: 0;
  right: 0;
  flex: none;
  color: $header-color-text;

  .inside {
    padding: 0;

    @include media-query(screen-md) {
      @include padding-default;

      width: 90%;

      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;

      &:after {
  	    display: none;
      }
    }

  }

  .header--relative & {
    position: relative;
    border-bottom: $base-border;
  }

  .header--invert & {
	  color: $header-color-text--invert;
  }

  .header--background & {
	  background: $header-color-background--inverted;
    background: $header-color-background--inverted-gradient;
  }
}

// #container
// [1] sticky footer via flexbox
// [2] IE10: sticky footer, fix gap between #container and #footer
[id="container"] {
  flex: 1 0 auto; // [1]
  overflow: hidden; // [2]
}

// #main
.mod_article {
	width: 91%;
	padding-top:    $base-spacing-unit;
  padding-bottom: $base-spacing-unit;

  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }

  // @include media-query(screen-xl) {
  //   padding-top:    $base-spacing-unit--xl;
  //   padding-bottom: $base-spacing-unit--xl;
  // }

  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
}

.article--full {
  @include wrapper-max(none);

  > .inside {
	  @include wrapper-max(none);
  }

  &.band {
	  > .inside {
		  @include wrapper-max(none);
	  }
  }

  padding: 0;
}

.article--left-full {
	@include wrapper-max(none);

	padding-left: 0;
	padding-right: 0;

	@include media-query(screen-sm) {
		padding-left: 0;
		padding-right: $base-spacing-unit--lg;
	}

	@include media-query(screen-xl) {
		padding-right: calc(((100vw - #{$wrapper-max}) / 2) + #{$grid__gutter--half});
	}
}

// #footer
[id="footer"] {
	flex: none;
	background: $footer-color-background;
	color: $footer-color-text;
	position: relative;
	z-index: 0;

	> .inside {
		width: 90%;
	}

}

// band
.band {
  @include wrapper-max(none);

  padding-left: 0;
  padding-right: 0;
  position: relative;

  .inside {
    @include wrapper-max();
    @include padding-default();

    width: 90%;
  }
}

.band--highlight {
  background: $color-band-highlight-background;
  color: #fff;

  a {
    color: inherit;
  }

  p {
    font-weight: 500;
  }
}

.band--light {
	background-color: $color-gray-light;
}

.band--tint {
  background: linear-gradient(90deg, #ee7814 0%, #ef7814 100%);
}

.band--bg {
  background-image: url(/files/theme/dist/img/windraeder.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}

.band--bg-image {
  .inside {
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: contain;
    padding: 4rem 0 8rem;
  }
}
