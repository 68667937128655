// *
// * Erdmann & Freunde
// * SIRIUS Contao Theme
// * erdmann-freunde.de/themes/sirius/
// *

//
// TRUMPS/HELPER
// --------------------------------------------------

// VARIABLES


// USED FROM _variables.scss
// $color-text--inverted
// $base-border
// $base-spacing-unit

// USED FROM _grid.scss
// $grid__columns

// USED FROM _responsive.scss
// $breakpoints


// --------------------------------------------------

.text--inverted,
.text--inverted * {
  color: $color-text--inverted;
}

.border-top {
  border-top: $base-border;
}

.border-bottom {
  border-bottom: $base-border;
}

.block--center {
  @include margin-auto;
}

// spacing classes for articles and elements beginning from .m-t-0 up to .m-t-5
@for $i from 0 through 5 {
  .m-t-#{$i},
  .mod_article.m-t-#{$i} {
    margin-top: $i * $base-spacing-unit;
  }

  .m-b-#{$i},
  .mod_article.m-b-#{$i} {
    margin-bottom: $i * $base-spacing-unit;
  }

  .p-t-#{$i},
  .mod_article.p-t-#{$i} {
    padding-top: $i * $base-spacing-unit;
  }

  .p-b-#{$i},
  .mod_article.p-b-#{$i} {
    padding-bottom: $i * $base-spacing-unit;
  }
}


// article widths container .article -[viewport]-[cols]
//
// [1] length($breakpoints) - go through length of available viewports (xs, sm, md, lg, xl == 5)
// [2] $current - actual viewport (e.g. "xs")
// [3] $i - counter, returns the actual grid-column
// [4] $grid__columns - go through available columns (e.g. 1 --> 12)
@for $size from 1 through length($breakpoints) { // [1]
  $x:   nth($breakpoints, $size); // [2]
  $current:   nth($x, 1); // [2]

  // padding creation
  @for $i from 1 through $grid__columns { // [3][4]
    .article-#{$current}-#{$i} {
      padding-left: 0;
      padding-right: 0;

      .inside { // [2][3]
        @include padding-default;
        @include margin-auto;
      }
    }
  }

  .article-#{$current}-left {
	  > .inside {
		  margin-left: 0;
	  }
  }

  .article-#{$current}-right {
	  > .inside {
		  margin-right: 0;
	  }
  }

  // width creation for every article- class
  @include media-query(screen-#{$current}) { // [2]

  	@for $i from 1 through $grid__columns { // [3][4]
  		.article-#{$current}-#{$i} .inside { // [2][3]
        width: percentage($i / $grid__columns);
  		}
  	}
  }
}

// Flex
.flex {
  display: flex !important;


  // &--sm {
  //   @media screen and (min-width: $screen-sm) {
  //     display: flex;
  //   }
  // }

  &-wrap {
    flex-wrap: wrap;
  }

  &--column {
    flex-direction: column;
  }
}

.space-between {
    justify-content: space-between;
}

.pos-r {
  position: relative;
}

