// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/GRID
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/grid";

// --------------------------------------------------

// [1] IE 11: bug with a flexbox parent and row-child, causing to calculate percentages from the parent
.row {
  min-width: 100%; // [1]
}

.row--flex {
  display: flex;
  flex-wrap: wrap;
  //justify-content: flex-start;

  > * {
    display: flex;
    flex-direction: column;
    //justify-content: flex-start;
  }
  
  .align-bottom {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.row--reverse {
  
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.row--center {
  > * {
    justify-content: center;
    
    > * {
      //margin-bottom: 0;
    }
  }
}
