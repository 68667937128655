// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// BASE/PAGE
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/page";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

html,
body {
	text-rendering: optimizeLegibility;
}
