/**
  * Erdmann & Freunde - Nutshell
  * Starterkit
  * erdmann-freunde.de/nutshell/
  *
  * Lizenziert unter MIT OPEN SOURCE
  *
  */

/** Contents
  *
  * SETTINGS
  * ...variables
  *
  *
  * MIXINS
  * ...general
  * ...responsive
  *
  *
  * GENERIC
  * ...normalize
  * ...contao-reset
  * ...box-sizing
  *
  *
  * BASE
  * ...shared
  * ...page
  * ...type
  * ...grid
  * ...layout
  *
  *
  * COMPONENTS
  * ...media
  * ...tables
  * ...accordion
  * ...links
  * ...events
  * ...faq
  * ...forms
  * ...navs
  * ...news
  * ...newsletter
  * ...slider
  * ...downloads
  *
  */

// VARIABLES
@import "variables";


// MIXINS
@import "mixins/general";
@import "mixins/responsive";


// GENERIC
@import "../../../nutshell/scss/generic/normalize";
@import "../../../nutshell/scss/generic/contao-reset";
@import "../../../nutshell/scss/generic/box-sizing";


// BASE
@import "base/shared";
@import "base/page";
@import "base/fonts";
@import "base/type";
@import "base/grid";
@import "base/layout";


// COMPONENTS
@import "components/media";
@import "components/links";
@import "components/navs";
@import "components/slider";
@import "components/news";
// @import "components/events";
@import "components/forms";
@import "components/accordion";
@import "components/downloads";
// @import "components/tables";
// @import "components/newsletter";
// @import "components/faq";
// @import "components/comments";
// @import "components/search";
@import "components/logo";
@import "components/hero";
// @import "components/card";
@import "components/icons";
// @import "components/modal";
// @import "components/member";
@import "components/image-text";
@import "components/quote";
@import "components/list";

// TRUMPS
@import "trumps/helper";
