// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/media";

// --------------------------------------------------


.ce_image {

}

.caption--right {
	text-align: right;
	
	img {
  	margin-left: auto;
  	margin-right: 0;
	}

	.caption {
		text-align: right;
	}
}

.ce_gallery {
	img {
		border: $base-border;
		padding: $base-spacing-unit--xs;
	}
}
