// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *
//
// COMPONENTS/LOGO
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-font-family-sans-serif

// USED FROM _layout.scss
// $header-height

// --------------------------------------------------

// company brand
.logo {
  background-color: #ffffff;
  margin-right: $base-spacing-unit - 0.4;

  padding: 3rem 1.8rem 2rem;
  margin-top: -6rem;
  transform: $base-skew;

  position: relative;
  top: 5rem;
  left: 0;
  z-index: 0;

  max-width: 24rem;

  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    margin-top: -5rem;
    max-width: 16rem;
    width: 100%;
  }

  @media screen and (min-width: 1301px) {
    max-width: 100%;
  }

  a {
    color: inherit;
    display: block;
    transform: $base-skew--balance;
  }
}
