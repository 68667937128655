/**
 * @component 			List
 * @project 			Gemeindewerke St. Michel
 * @last-modified		2019-05-15
 * @authors				Yannick Herzog <info@hit-services.net>
 */

.list {

	&--numbered {
		ol,
		ul {
			list-style: none;
			counter-reset: my-awesome-counter;
			display: flex;
  			flex-wrap: wrap;
  			flex-direction: column;

			padding-left: 0;
			margin-left: 0;

  			@include media-query(screen-md) {
  				padding-left: $base-spacing-unit;
				margin-left: $base-spacing-unit;
			}
		}

		li {
			counter-increment: my-awesome-counter;
			font-size: 0.9rem;
			line-height: 1.3rem;
			display: flex;
  			width: 100%;

  			&:not(:first-child) {
  				margin-top: 1.2rem;
  			}

			&:before {
				content: "0" counter(my-awesome-counter) "\00A0/";
				font-weight: bold;
				font-size: 1.6rem;
				margin-right: 1.8rem;
			}
		}
	}
}
