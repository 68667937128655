// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/HERO
// --------------------------------------------------

// VARIABLES
$hero__color-background: $color-page-background;
$hero__color-text:       $color-text;
$hero__font-family:      $base-font-family;
$hero__font-weight:      700;

// USED FROM _variables.scss
// $base-spacing-unit, --lg

// USED FROM _layout.scss
/// $header-height

// --------------------------------------------------


// animations
@keyframes scale-up {
	0% {
		transform: scale3d(1,0,1);
	}

	100% {
		transform: scale3d(1,1,1);
	}
}

@keyframes scale-up-down {
	0% {
		transform: scale(0.9,0);
	}

	50% {
		transform: scale(0.9,1);
	}

	100% {
		transform: scale(0.9,0);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// hero
// [1] every .inside container has position relative by contao default
.mod_article.hero {
  @include wrapper-max(none);

  background: $hero__color-background;
  min-height: 50vh;
  color: $hero__color-text;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 0;

  > .inside {
    @include padding-default;
    @include wrapper-max;

    width: 90%;
    position: static; // [1]
    min-height: inherit;
    // overflow: hidden; disabled for animation

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // flex: 0 0 auto;
  }

}

// [1] IE10 calculating wrong widths, when max-width isn't set
// [2] make sure sizing and text-aligning is correct, when headline and paragraph are shorter than 2 lines
.ce_hero {
	display: flex;
	overflow: visible;
	align-items: flex-start;
	justify-content: center;
	width: 100%;

	.content_container {
		background-color: transparentize($color-blue-dark, 0.6);
		color: $color-text--inverted;
		text-align: center;
		position: relative;
		z-index: 1000;
		margin-top: -6rem;

		padding: 6rem 1rem 3rem;
  		transform: $base-skew;

  		@include media-query(screen-lg) {
    		padding: 6rem 2.5rem 3rem;
		}


		&--center {
			text-align: center;
		}

		.ie10 & {
			max-width: 100%; // [1]
		}
	}

	.teaser {
		animation: fade-in 0.3s 0.5s;
		animation-fill-mode: backwards;

		margin-top: $base-spacing-unit--xl;
		transform: $base-skew--balance;

		@include media-query(screen-md) {
    		margin-top: $base-spacing-unit--xl + 1.5;
  		}
  }

  .headline {
  		color: inherit;
		font-size: 1.6rem;
		font-weight: 700;
		font-family: $headings__font-family;
		line-height: 1.8rem;
		hyphens: auto;
		text-transform: uppercase;
		margin-bottom: 0.5rem;

		@include media-query(screen-md) {
    		font-size: 2.5rem;
    		line-height: 1.1em;
    		margin-bottom: 0.7rem;
			word-spacing: 4px;
  		}
  }

	.description {
		margin-bottom: $base-spacing-unit + 0.5;

		p {
			color: inherit;
			font-size: 1.2rem;
			font-weight: 400;

			@include media-query(screen-md) {
    			font-size: 1.8rem;
  			}
		}
	}

	.button_container {
		.btn {
			margin: $base-spacing-unit--xs $base-spacing-unit--xs;
		}
	}

	.image_container {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  z-index: -1;

	  img {
	    height: 100%;
	    width: 100%;
	    object-fit: cover;
	  }
	}
}

.hero__image {
	position: relative;

	img {
		width: 100%;
		height: auto;
	}

	.inside {
		@include wrapper-max();
		
		position: relative;
		width: 90%;
	}

	.hero__text {
		@include wrapper-max();
    @include padding-default();
    
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 10;
	}
}

.hero__text {
	text-align: right;
	margin-bottom: 0;

	&--handwrite,
	&--handwrite * {
		color: $color-text--inverted;
		font-family: $accent-font-family;
		font-size: 5rem;
		line-height: 6rem;

		@include media-query(screen-md) {
    		font-size: 8rem;
			line-height: 9.5rem;
  		}
	}
}
