// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/forms";

// --------------------------------------------------


label,
legend {
  display: inline-block;
  max-width: 100%; // [3]
  margin-bottom: $base-spacing-unit--xs;
  font-weight: 400;
}

p.error {
  color: red;
}


input.text,
input.captcha,
textarea {
	padding: $base-spacing-unit--xs $base-spacing-unit--sm;
	
}

.widget-radio,
.widget-checkbox,
.widget-select,
.widget-upload {
  margin-bottom: $base-spacing-unit;
  
  legend {
    font-weight: 400;
  }
}

.widget-checkbox {
  input.checkbox {
    float: left;
  }
  
  input.checkbox {
    position: absolute;
    opacity: 0;
  }
  
  label {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    
    &::before {
      margin-top: $base-spacing-unit--xs;
      margin-right: $base-spacing-unit;
      content: ' ';
      text-align: center;
      color: $color-brand;
      float: left;
      display: block;
      width: 1rem;
      height: 1.62rem;
      font-size: 1.62em;
      line-height: 1;
      box-sizing: content-box;
      padding: $base-spacing-unit--xs $base-spacing-unit--sm;
      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;
      background: $color-page-background;
    }
    
    .label__text {
      display: block;
      overflow: hidden;
    }
    
    a {
      text-decoration: underline;
    }
  }
  
  input:checked + label {
    &::before {
      content: '✓';
    }
  }
}

.widget-checkbox--hidelabel {

  legend {
    @include invisible;
  }
  
  .checkbox_container > span {
    display: block;
  }
  
  input.checkbox {
    display: none;
  }
  
  label {
    margin-left: 0;
    
    &::before {
      display: none;
    }
    
    &::after {
      margin-top: $base-spacing-unit--xs;
      content: ' ';
      text-align: center;
      color: $color-brand;
      display: block;
      width: 1rem;
      height: 1.62rem;
      font-size: 1.62em;
      line-height: 1;
      box-sizing: content-box;
      padding: $base-spacing-unit--xs $base-spacing-unit--sm;
      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;
      background: $color-page-background;
    }
  }
  
  input:checked + label {
    &::after {
      content: '✓';
    }
  }
}

.member-registration {
  
}
