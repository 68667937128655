// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

//@import "../../../../nutshell/scss/components/slider";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

.ce_sliderStart {
	@include make-row();

	overflow: visible;
}

.slick-slide {
	opacity: 0;
	transition: opacity 0.6s;
	background: $color-brand;

	&.slick-active {
		opacity: 1;
		background: none;
	}
}

.slick-dots {
	clear: both;
	list-style-type: none;
	padding: 0;
	margin: 0;
	text-align: center;

	li {
		display: inline-block;

		&:focus {
			outline: none;
		}
	}

	button {
		margin: 0 $base-spacing-unit--xs/2;
		background: transparent;
		border: 2px solid $color-blue-gray;
		padding: 0;
		text-indent: -9999px;
		border-radius: 50%;
		font-size: 1px;
		width: 14px;
		height: 14px;

		&:focus {
			outline: none;
		}
	}

	.slick-active {
		button {
			background: $color-blue-gray;
		}
	}
}

.slick-slide {
	&:focus {
		outline: none;
	}
}

.slick-track {
  display: flex;
  flex-wrap: wrap;
  //justify-content: flex-start;

  > * {
    display: flex;
    flex-direction: column;
  }
}
