// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/CARD
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.ext-pdf {
	background-image: url(/files/theme/dist/img/icons/pdf.png);
	background-position: left center;
	padding: 12px 6px 12px 64px;
}

.ext-zip {
	background-image: url(/files/theme/dist/img/icons/icon_zip.png);
	background-position: left center;
	padding: 12px 6px 12px 64px;
}

.ext-xls,
.ext-xlsx {
	background-image: url(/files/theme/dist/img/icons/icon_xls.png);
	background-position: left center;
	padding: 12px 6px 12px 64px;
}
