// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

// VARIABLES
// General Colors
$color-gray-dark:   			#929292;
$color-gray-darker:				#4f4f4f;
$color-gray:					#555;
$color-gray-light:  			#f5f5f5; // used for navigation footer links


$color-blue-gray:				#595c63;
$color-blue-dark:				#525b6e;
$color-blue-dark-light:			#465f77;

$color-blue:					#8aa1b9;
$color-blue-lightest:			#f2f7fb;
$color-orange:					#f17a19;
$color-orange-light:			#f4a900;

$color-green:					#6ea325;

$color-tint:        			$color-gray-light;

$color-brand:       			$color-green;
$color-brand-secondary:			$color-orange;
$color-highlight:				$color-orange;

$color-text:					$color-blue-gray;

$color-btn:       				$color-green;
$color-btn-text:				#ffffff;

// Base Font Settings
$base-font-size--xl:      		1.125rem;

$base-font-family-sans-serif:	'Fira Sans', sans-serif;
$base-font-family:				$base-font-family-sans-serif;
$base-font-weight:				300;

$accent-font-family-serif:		'abugetregular', serif;
$accent-font-family:			$accent-font-family-serif;

$base-spacing-unit:         	1.625rem  !default;

// Base Border Settings
$base-border-radius:          	4px !default;
$base-border-width:           	1px !default;
$base-border-color:           	rgba(0,0,0,0.1);

$base-border:                 	$base-border-width solid $base-border-color;

// Bands
$color-band-highlight-background:     linear-gradient(180deg, #8DA6BC 0%, #71889f 50%, #8DA6BC 100%);
$color-band-tint-background:          linear-gradient(lighten($color-tint, 4%), darken($color-tint, 3%));
$color-band-dark-text:                #fff;
$color-band-dark-background:          $color-brand;

$base-overlay-background: 		  	rgba(#fff, 0.9);
$base-overlay-background--brand:	rgba($color-brand, 0.7);

$base-box-shadow:						0 0 10px rgba(0,0,0,0.26);
$base-text-shadow:						0.125em 0.125em $base-spacing-unit rgba(0,0,0,0.3);

$ie9-support: false;
$debug-mode: false;

// Skew
$base-skew:						skew(0deg, -3deg);
$base-skew--balance:			skew(0deg, 3deg);

// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------

