// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$button-border-width: 1px;
$button-border-radius: 2px;
$button-spacing: 			$base-spacing-unit--xs $base-spacing-unit;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/links";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

%button {
  font-size: 1.25rem;
	text-transform: uppercase;
  padding: 0.6rem 3.5rem 0.7rem $base-spacing-unit;
  position: relative;

  &:after {
    color: $color-text--inverted;
    content: '';
    width: 1.5rem;
    height: 100%;
    display: block;

    position: absolute;
    right: 1.3rem;
    top: 0;

    background-image: url(/files/theme/dist/img/icons/pfeil-kreis-rechts.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

// primary button
a.btn--primary,
span.btn--primary,
button.btn--primary {

  .hero & {
	  background: $color-brand;
	  border-width: 0;
	  color: $color-text--inverted;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: initial;
  }

}


// secondary button
a.btn--secondary,
span.btn--secondary,
button.btn--secondary {

  .hero & {
    background-color: $color-brand-secondary;
    border-width: 0;
    color: $color-text--inverted;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: initial;
  }
}

// tertiary button
a.btn--tertiary,
span.btn--tertiary,
button.btn--tertiary {
  background-color: $color-blue-dark;
}

