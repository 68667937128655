// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/DOWNLOADS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

//@import "../../../../nutshell/scss/components/downloads";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

.ce_downloads {
  ul {
    margin-left: 0;
    padding-left: 0;
  }
}

/*
.download-element {
	a {
		color: $color-brand;
		text-decoration: underline;
	}
}

*/