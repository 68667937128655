// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES
$accordion-background: 				#ffffff;
$accordion-border:					#d4d4d4;
$accordion-padding-left-right: 		2.5rem;

$toggler-font-size: 		1rem;
$toggler-background:		#ffffff;
$toggler-color:				$color-blue-gray;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/accordion";

// --------------------------------------------------

// accordion
.ce_accordion {

	&.last {
		margin-bottom: $base-spacing-unit;
	}

	.toggler {
		border: 1px solid $accordion-border;
		color: $toggler-color;
		padding-right: 30px;
		padding-left: $accordion-padding-left-right;
		position: relative;

		transition: all .2s ease-in-out;

		&:hover {
			color: $toggler-color;
		}

		&:after {
			content: "+";
			font-size: 2.5rem;

			position: absolute;
			right: 1.25rem;
			top: 0;

			height: 100%;
			width: 20px;

			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:focus {
			outline: 0;
		}

		&.ui-state-active {
			background-color: $color-blue;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			color: #ffffff;

			&:after {
				content: "–";
			}
		}
	}

	.accordion {
		border: 1px solid $accordion-border;
		border-top: 0;
		border-radius: 0;
		padding-right: $accordion-padding-left-right;
		padding-left: $accordion-padding-left-right;
	}
}
