// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE
// *

//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

/* fira-sans-300 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Fira Sans Light'), local('FiraSans-Light'),
       url('../fonts/fira-sans-v9-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-300italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
       url('../fonts/fira-sans-v9-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-regular - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
       url('../fonts/fira-sans-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Fira Sans Italic'), local('FiraSans-Italic'),
       url('../fonts/fira-sans-v9-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-500 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Fira Sans Medium'), local('FiraSans-Medium'),
       url('../fonts/fira-sans-v9-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-500italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
       url('../fonts/fira-sans-v9-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
       url('../fonts/fira-sans-v9-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
       url('../fonts/fira-sans-v9-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/fira-sans-v9-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 15, 2019 */
@font-face {
    font-family: 'abugetregular';
    src: url('../fonts/abuget-webfont.woff2') format('woff2'),
         url('../fonts/abuget-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
