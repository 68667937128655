/**
 * @component 			Image Text
 * @project 			Gemeindewerke St. Michel
 * @last-modified		2019-05-15
 * @authors				Yannick Herzog <info@hit-services.net>
 */

.image-text {
	overflow: visible;

	&--horz {
		display: flex;
		align-items: flex-start;
	}

	&__image-container {
		width: 100%;
		max-width: 3rem;
		transform: translateY(10%);

		@include media-query(screen-lg) {
			min-width: 5rem;
			max-width: 5rem;
			transform: translateY(-15%);
		}
	}

	&__content-container {
		margin-left: 1.5rem;

		@include media-query(screen-lg) {
			margin-left: 1rem;
		}
	}

	+ .btn {
		margin-left: 4.5rem;

		@include media-query(screen-lg) {
			margin-left: 6rem;
		}
	}
}
